import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

//CSS 
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/fonts/fontawesome/css/all.min.css"
import "./assets/css/default.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import './assets/fonts/circularstd/CircularStdBold.woff2';
import './assets/fonts/circularstd/CircularStdMedium.woff2';
import './assets/fonts/circularstd/CircularStdBook.woff2';
import './assets/fonts/fontawesome/webfonts/fa-regular-400.woff2';
import './assets/fonts/fontawesome/webfonts/fa-solid-900.woff2';


import TagManager from 'react-gtm-module'
const tagManagerArgs = { gtmId: `${process.env.REACT_APP_PUBLIC_GTM_ID}`};
TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorkerRegistration.register({
	onUpdate: sw => console.log('SW Update', sw),
	onSuccess: sw => console.log('SW Success', sw)
});
