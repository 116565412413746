import { Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { transitions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { Helmet, HelmetProvider } from "react-helmet-async";
// Routing
import PrivateRoute from "./components/routing/PrivateRoute";
import AdminRoute from "./components/routing/AdminRoute";
import VisitorRoute from "./components/routing/VisitorRoute";
import ScrollToTop from "./components/ScrollToTop";

// import Test from './Test';

// Pages
const Login = lazy(() => import("./components/pages/Login"));
const Register = lazy(() => import("./components/pages/Register"));
const AdminLogin = lazy(() => import("./components/pages/admin/AdminLogin"));
const AdminRegister = lazy(() => import("./components/pages/admin/AdminRegister"));
const VisitorLogin = lazy(() => import("./components/pages/Visitor/VisitorLogin"));
const VisitorRegister = lazy(() => import("./components/pages/Visitor/VisitorRegister"));
const Error = lazy(() => import("./components/pages/error/Error"));
const Calculator = lazy(() => import("./components/pages/pricing/Calculator"));
const MumbaiPV = lazy(() => import("./components/pages/FAQ/MumbaiPV"));
const ThanePV = lazy(() => import("./components/pages/FAQ/ThanePV"));
const PunePV = lazy(() => import("./components/pages/FAQ/PunePV"));

//home page
const Home = lazy(() => import("./components/pages/home/Home"));
const FAQ = lazy(() => import("./components/pages/FAQ/FAQ"));
const Pricing = lazy(() => import("./components/pages/pricing/Pricing"));

//cities
const Mumbai = lazy(() => import("./components/pages/cities/mumbai/Mumbai"));
const Aurangabad = lazy(() => import("./components/pages/cities/aurangabad/Aurangabad"));
const Jalgaon = lazy(() => import("./components/pages/cities/jalgaon/Jalgaon"));
const Kolhapur = lazy(() => import("./components/pages/cities/kolhapur/Kolhapur"));
const Latur = lazy(() => import("./components/pages/cities/latur/Latur"));
const Nagpur = lazy(() => import("./components/pages/cities/nagpur/Nagpur"));
const Nanded = lazy(() => import("./components/pages/cities/nanded/Nanded"));
const Nashik = lazy(() => import("./components/pages/cities/nashik/Nashik"));
const PCMC = lazy(() => import("./components/pages/cities/PCMC/PCMC"));
const Pune = lazy(() => import("./components/pages/cities/pune/Pune"));
const Satara = lazy(() => import("./components/pages/cities/satara/Satara"));
const Solapur = lazy(() => import("./components/pages/cities/solapur/Solapur"));
const Thane = lazy(() => import("./components/pages/cities/thane/Thane"));
const Baramati = lazy(() => import("./components/pages/cities/baramati/Baramati"));

//pvt pages
const Profile = lazy(() => import("./components/pages/profile/Profile"));
const EditProfile = lazy(() => import("./components/pages/profile/EditProfile"));
const Dashboard = lazy(() => import("./components/pages/dashboard/Dashboard"));
const FormWizard = lazy(() => import("./components/pages/form/Form"));
const View = lazy(() => import("./components/pages/View/View"));
const Visit = lazy(() => import("./components/pages/visit/Visit"));
const Summary = lazy(() => import("./components/pages/summary/Summary"));
const Payment = lazy(() => import("./components/pages/payment/Payment"));
const UserUploadDocs = lazy(() => import("./components/pages/View/UploadDocuments"));
const UserTransactions = lazy(() => import("./components/pages/payment/Transactions"));

//admin only
const AdminDashboard = lazy(() => import("./components/pages/admin/dashboard/AdminDashboard"));
const AssignVisitor = lazy(() => import("./components/pages/admin/visitor/AssignVisitor"));
const VisitList = lazy(() => import("./components/pages/admin/visitor/VisitList"));
const AdminVisit = lazy(() => import("./components/pages/admin/visitor/AdminVisit"));
const AdminTransactions = lazy(() => import("./components/pages/admin/transactions/AdminTransactions"));
const Users = lazy(() => import("./components/pages/admin/users/Users"));
const UserEdit = lazy(() => import("./components/pages/admin/users/UserEdit"));
const AdminSummary = lazy(() => import("./components/pages/admin/summary/Summary"));
const Admins = lazy(() => import("./components/pages/admin/Admins"));
const AdminFormWizard = lazy(() => import("./components/pages/admin/form/Form"));
const AdminProfile = lazy(() => import("./components/pages/admin/AdminProfile"));
const AdminEditProfile = lazy(() => import("./components/pages/admin/AdminEditProfile"));
const Visitors = lazy(() => import("./components/pages/admin/visitor/Visitors"));
const Visits = lazy(() => import("./components/pages/admin/visitor/Visits"));
const VisitorEdit = lazy(() => import("./components/pages/admin/visitor/VisitorEdit"));
const VisitPayment = lazy(() => import("./components/pages/admin/visitor/VisitPayment"));
const AddCredentials = lazy(() => import("./components/pages/admin/visitor/AddCredentials"));
const AdminView = lazy(() => import("./components/pages/admin/view/View"));
const ManualPayment = lazy(() => import("./components/pages/admin/transactions/ManualPayment"));
const Leads = lazy(() => import("./components/pages/admin/leads/Leads"));
const EditLeads = lazy(() => import("./components/pages/admin/leads/EditLeads"));
const AdminUploadDocs = lazy(() => import("./components/pages/admin/view/UploadDocuments"));
const AddMoreLeads = lazy(() => import("./components/pages/admin/moreleads/AddMoreLeads"));
const EditMoreLeads = lazy(() => import("./components/pages/admin/moreleads/EditMoreLeads"));
const MoreLeads = lazy(() => import("./components/pages/admin/moreleads/MoreLeads"));

//visitor only
const AddCityRates = lazy(() => import("./components/pages/Visitor/AddCityRates"));
const VisitorDashboard = lazy(() => import("./components/pages/Visitor/VisitorDashboard"));
const MarkDone = lazy(() => import("./components/pages/Visitor/MarkDone"));
const PastVisits = lazy(() => import("./components/pages/Visitor/PastVisits"));
const VisitorProfile = lazy(() => import("./components/pages/Visitor/VisitorProfile"));
const VisitorEditProfile = lazy(() => import("./components/pages/Visitor/VisitorEditProfile"));

const App = () => {
  const options = {
    position: "bottom center",
    timeout: 3000,
    offset: "30px",
    transition: transitions.SCALE
  };
  // const { pathname } = useLocation();
  // useLayoutEffect(() => window.scrollTo(0, 0), [pathname]);
  return (
    <HelmetProvider>
      <AlertProvider template={AlertTemplate} {...options}>
        <Helmet>
          <title>Online Rent Agreement | Leave and License Registration </title>
          <meta name="title" content="Online Rent Agreement | Leave and License Registration" />
          <meta
            name="description"
            content="Online Rent Agreement Registration. Get your Biometrics done at comfort of your home. Government Approved Registered Rent Agreement at your Doorstep."
          />

          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.rentagreement.co.in/" />
          <meta property="og:title" content="Online Rent Agreement | Leave and License Registration" />
          <meta
            property="og:description"
            content="Online Rent Agreement Registration. Get your Biometrics done at comfort of your home. Government Approved Registered Rent Agreement at your Doorstep."
          />
          <meta property="og:image" content="" />

          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://www.rentagreement.co.in/" />
          <meta property="twitter:title" content="Online Rent Agreement | Leave and License Registration" />
          <meta
            property="twitter:description"
            content="Online Rent Agreement Registration. Get your Biometrics done at comfort of your home. Government Approved Registered Rent Agreement at your Doorstep."
          />
          <meta property="twitter:image" content="" />
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "http://schema.org/",
              "@type": "Product",
              name: "Rent Agreement Registration Online",
              aggregateRating: {
                "@type": "AggregateRating",
                ratingValue: "4.8",
                ratingCount: "370",
                reviewCount: "370"
              }
            })}
          </script>

          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "http://www.schema.org",
              "@type": "Organization",
              name: "Landio Rent Agreement",
              url: "https://www.rentagreement.co.in",
              logo: "https://www.rentagreement.co.in/logo-square.png",
              image: "https://www.rentagreement.co.in/logo-square.png",
              description:
                "Landio Rent Agreement is a Government approved Doorstep service provider for Rent Agreement Registration or Leave & license Agreement Registration online.",
              address: {
                "@type": "PostalAddress",
                streetAddress: "2, Susheetal Appt, Padma Society, Bibwewadi, Pune, Maharashtra 411037 ",
                addressLocality: "Pune",
                addressRegion: "Maharashtra",
                postalCode: "411037",
                addressCountry: "India"
              },
              contactPoint: {
                "@type": "ContactPoint",
                telephone: "+918806242436",
                contactType: "Customer Care"
              }
            })}
          </script>
        </Helmet>
        {/* <ScrollToTop> */}
        <Router>
          <Suspense
            fallback={
              <div id="spinneroverlay">
                <div className="spinner"></div>
              </div>
            }>
            <ScrollToTop>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/faq" component={FAQ} />
                <Route exact path="/pricing" component={Pricing} />
                <Route exact path="/calculate" component={Calculator} />
                <Route exact path="/mumbai-police-verification" component={MumbaiPV} />
                <Route exact path="/thane-police-verification" component={ThanePV} />
                <Route exact path="/pune-police-verification" component={PunePV} />

                <PrivateRoute exact path="/profile" component={Profile} />
                <PrivateRoute exact path="/editprofile" component={EditProfile} />
                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                <PrivateRoute exact path="/transactions" component={UserTransactions} />
                <PrivateRoute exact path="/form/:id" component={FormWizard} />
                <PrivateRoute exact path="/view/:id" component={View} />
                <PrivateRoute exact path="/visit/:id" component={Visit} />
                <PrivateRoute exact path="/summary/:id" component={Summary} />
                <PrivateRoute exact path="/payment/:id" component={Payment} />
                <PrivateRoute exact path="/userdocs/:id" component={UserUploadDocs} />

                <AdminRoute exact path="/admindashboard" component={AdminDashboard} />
                <AdminRoute exact path="/admindashboard/:id" component={AdminDashboard} />
                <AdminRoute exact path="/adminview/:id" component={AdminView} />
                <AdminRoute exact path="/assignvisitor/:id" component={AssignVisitor} />
                <AdminRoute exact path="/visitors" component={Visitors} />
                <AdminRoute exact path="/visits/:id" component={Visits} />
                <AdminRoute exact path="/visitor/:id" component={VisitorEdit} />
                <AdminRoute exact path="/adminprofile" component={AdminProfile} />
                <AdminRoute exact path="/admineditprofile" component={AdminEditProfile} />
                <AdminRoute exact path="/visitlist/:id" component={VisitList} />
                <AdminRoute exact path="/visitpayment" component={VisitPayment} />
                <AdminRoute exact path="/adminvisit/:id" component={AdminVisit} />
                <AdminRoute exact path="/adminsummary/:id" component={AdminSummary} />
                <AdminRoute exact path="/manualpayment/:id" component={ManualPayment} />
                <AdminRoute exact path="/users" component={Users} />
                <AdminRoute exact path="/user/:id" component={UserEdit} />
                <AdminRoute exact path="/admins" component={Admins} />
                <AdminRoute exact path="/adminform/:id" component={AdminFormWizard} />
                <AdminRoute exact path="/addcredentials/:id" component={AddCredentials} />
                <AdminRoute exact path="/leads" component={Leads} />
                <AdminRoute exact path="/leads/:id" component={EditLeads} />
                <AdminRoute exact path="/admintransactions" component={AdminTransactions} />
                <AdminRoute exact path="/admindocs/:id" component={AdminUploadDocs} />
                <AdminRoute exact path="/addmoreleads" component={AddMoreLeads} />
                <AdminRoute exact path="/editmoreleads/:id" component={EditMoreLeads} />
                <AdminRoute exact path="/moreleads" component={MoreLeads} />

                <VisitorRoute exact path="/visitordashboard" component={VisitorDashboard} />
                <VisitorRoute exact path="/visitorprofile" component={VisitorProfile} />
                <VisitorRoute exact path="/visitoreditprofile" component={VisitorEditProfile} />
                <VisitorRoute exact path="/addcityrates" component={AddCityRates} />
                <VisitorRoute exact path="/pastvisits" component={PastVisits} />
                <VisitorRoute exact path="/markdone/:id" component={MarkDone} />

                <Route exact path="/login" component={Login} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/adminlogin" component={AdminLogin} />
                <Route exact path="/adminregister" component={AdminRegister} />
                <Route exact path="/visitorlogin" component={VisitorLogin} />
                <Route exact path="/visitorregister" component={VisitorRegister} />

                <Route exact path="/online-rent-agreement-aurangabad" component={Aurangabad} />
                <Route exact path="/online-rent-agreement-jalgaon" component={Jalgaon} />
                <Route exact path="/online-rent-agreement-kolhapur" component={Kolhapur} />
                <Route exact path="/online-rent-agreement-latur" component={Latur} />
                <Route exact path="/online-rent-agreement-mumbai" component={Mumbai} />
                <Route exact path="/online-rent-agreement-nagpur" component={Nagpur} />
                <Route exact path="/online-rent-agreement-nanded" component={Nanded} />
                <Route exact path="/online-rent-agreement-nashik" component={Nashik} />
                <Route exact path="/online-rent-agreement-PCMC" component={PCMC} />
                <Route exact path="/online-rent-agreement-pune" component={Pune} />
                <Route exact path="/online-rent-agreement-satara" component={Satara} />
                <Route exact path="/online-rent-agreement-solapur" component={Solapur} />
                <Route exact path="/online-rent-agreement-thane" component={Thane} />
                <Route exact path="/online-rent-agreement-baramati" component={Baramati} />

                <Route path="/" component={Error} />
              </Switch>
            </ScrollToTop>
          </Suspense>
        </Router>
        {/* </ScrollToTop> */}
        {/* WhatsApp icon */}
        <a href="https://wa.me/+918806242436" className="whatsapp_float" target="_blank" rel="noopener noreferrer">
          {" "}
          <i className="fas fa-whatsapp whatsapp-icon"></i>
        </a>
      </AlertProvider>
    </HelmetProvider>
  );
};

export default App;
