import { Redirect, Route } from "react-router-dom";

const VisitorRoute = ({ component: Component, ...rest }) => {
  return (
		<Route { ...rest }
			render={ props => localStorage.getItem("visitorToken") ?
				<Component {...props} /> :
				<Redirect to="/visitorlogin" />
			}
		/>
	);
};

export default VisitorRoute;
